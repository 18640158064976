import React from 'react'
import styled from 'styled-components'
import { colors } from '../../styles/globalStyles'
import { Spinner } from '../ui'

const Container = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.gray};
`

const LoadingSpinner = () => {
    return (
        <Container>
            <Spinner color={colors.red} />
        </Container>
    )
}

export default LoadingSpinner
