import React from 'react'
import styled from 'styled-components'

import DetailsTable from '../DetailsTable'
import { Card } from '../ui'

const Container = styled.div`
    display: flex;
    align-items: center;
`

const Title = styled.h5`
    text-transform: uppercase;
    margin-bottom: 0;
    margin-right: 20px;
    flex-basis: 20%;
`

const Time = styled.div`
    font-size: 40px;
    font-weight: bold;
    flex-basis: 30%;
`

const NextMeeting = ({ item, label }) => {
    return (
        <Card style={{ padding: '10px 30px' }}>
            <Container>
                <Title>{label}:</Title>
                <Time>{item.from} - {item.to}</Time>
                <DetailsTable
                    user={item.user_name}
                    peopleQuantity={item.people_quantity}
                    topic={item.topic}
                    description={item.description}
                />
            </Container>
        </Card>
    )
}

export default NextMeeting
