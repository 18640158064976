import axios from '../axios'

class MeetingRoomService {
    static getById = id => {
        return axios.get(`/meetingRooms/${id}`);
    }

    static getByToken = token => {
        return axios.get(`/meetingRooms/getByToken/${token}`);
    }

    static login = (username, password) => {
        return axios.post('/meetingRooms/login', {
            username,
            password
        });
    }

    static getMeetingsByDate = (token, date) => {
        return axios.get(`/meetingRooms/getMeetingsByDate/${token}?date=${date}`);
    }
}

export default MeetingRoomService