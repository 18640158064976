import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styles/globalStyles'

const Wrapper = styled.div`
    width: 100%;
    text-align: center;
    margin: 0px 20px;
`

const Container = styled.div`
    background-color: ${colors.black};
    height: 15px;
    border-radius: 20px;
    width: 100%;
`

const ContainerInner = styled.div`
    background-color: ${colors.red};
    transition: .3s all ease;
    height: inherit;
    border-radius: inherit;
    width: ${props => props.percent}%;
`

const Hint = styled.div`
    font-size: 40px;
    align-self: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Timer = styled.p`
    font-size: 60px;
    color: ${colors.white};
    margin: 0;
`

const ProgressBar = ({ from, to, percent = 0, hint }) => {
    return (
        <Wrapper>
            <Hint>
                <Timer>{from}</Timer>
                <p className="mb-0">{hint()}</p>
                <Timer>{to}</Timer>
            </Hint>
            <Container>
                <ContainerInner percent={percent} />
            </Container>
        </Wrapper>
    )
}

export default ProgressBar
