import React, { createContext, useState, useEffect } from 'react'

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [isAuth, setIsAuth] = useState(!!localStorage.getItem('mr_token'));

    useEffect(() => {
        let token = localStorage.getItem('mr_token');

        if(token) {
            setIsAuth(true);
        }
    }, [isAuth])

    const setToken = (token) => {
        return new Promise(resolve => {
            localStorage.setItem('mr_token', token);
            setIsAuth(true);
            resolve(true);
        });
    }

    const logout = () => {
        return new Promise((resolve, reject) => {
            localStorage.removeItem('mr_token');
            setIsAuth(false);
            resolve(true);
        });
    }

    return (
        <AuthContext.Provider 
            value={{
                isAuth,
                setToken,
                logout,
                setLoading,
                loading 
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider;