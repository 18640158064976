import React from 'react'
import styled from 'styled-components'

const List = styled.table`
    margin: 0;
    display: block;
    flex: 1;

    tbody > tr > td:first-child {
        padding-right: 40px;
    }
`

const DetailsTable = ({ user, peopleQuantity, topic, description }) => {
    return (
        <List>
            <tbody>
                <tr>
                    <td>Забронировал:</td>
                    <td>{user}</td>
                </tr>
                <tr>
                    <td>Количество участников:</td>
                    <td>{peopleQuantity}</td>
                </tr>
                <tr>
                    <td>Тема встречи:</td>
                    <td>{topic}</td>
                </tr>
                <tr>
                    <td>Описание:</td>
                    <td>{description}</td>
                </tr>
            </tbody>
        </List>
    )
}

export default DetailsTable
