import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styles/globalStyles'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

const InputWrapper = styled.div`
    position: relative;
`

const StyledInput = styled.input`
    border: none;
    outline: none;
    width: 100%;
    padding: 10px 15px;
    border-bottom: 3px solid #3E3E3E;
    background-color: rgba(0, 0, 0, 0.1);
    color: #ffffff;
    transition: 0.3s all ease;
    font-size: 14px;
    border-radius: 0;
    
    &:focus {
        border-color: ${colors.red};
        background-color: rgba(0, 0, 0, 0.2);
    }
`

const Label = styled.label`
    font-size: 14px;
    margin-bottom: 0;
`

const ErrorLabel = styled.span`
    color: ${colors.red};
    font-size: 12px;
`

const ShowPasswordIcon = styled.a`
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    color:  ${colors.white};
    transition: .3s all ease;
    font-size: 20px;

    &:hover {
        color: ${colors.red}
    }
`

const Input = ({ type = 'text', label, error, ...rest }) => {
    const [showPassword, setShowPassword] = React.useState(false);
    return (
        <>
            {/* Label component */}
            {label ? <Label>{label}</Label> : null}

            <InputWrapper style={{ position: 'relative' }}>
                <StyledInput
                    {...rest}
                    type={type !== 'password' || showPassword ? 'text' : 'password'}
                    style={{
                        paddingRight: type === 'password' ? '50px' : null
                    }}
                />
                {type === 'password' ? (
                    <ShowPasswordIcon onClick={() => setShowPassword(prev => !prev)}>
                        {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                    </ShowPasswordIcon>
                ) : null}
            </InputWrapper>

            {/* Error label component */}
            {error ? <ErrorLabel>* {error}</ErrorLabel> : null}
        </>
    )
}

export default Input
