import React from 'react'
import ReactCalendar from 'react-calendar';
import styled from 'styled-components';
import './styles.css'
import moment from 'moment'

const Container = styled.div`
    margin: 10px;
`

const Calendar = ({ busyDays = [], onDayClick }) => {
    return (
        <Container>
            <ReactCalendar
                next2Label={null}
                prev2Label={null}
                // showNeighboringMonth={false}
                tileContent={({ activeStartDate, date, locale, view }) => view === 'decade' ? <abbr>{date.getFullYear()}</abbr> : null}
                onClickDay={onDayClick}
                tileClassName={({ date, view }) => {
                    if(busyDays.find(x => moment(x).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'))) {
                        return  'react-calendar__tile--active'
                    }
                }}
            />
        </Container>
    );
}

export default Calendar
