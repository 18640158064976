import axios from 'axios'
import config from './config'

const instance = axios.create({
    baseURL: config.apiURL
});

// Response interceptor
instance.interceptors.response.use(function(response) {
    return response;
}, function(error) {
    return Promise.reject(error);
});

export default instance