import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styles/globalStyles'
import CardHeader from './CardHeader'

const Container = styled.div`
    background-color: ${colors.gray};
    border-radius: 10px;
    color: ${colors.white};
    margin: 10px;
`

const Body = styled.div`
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
`

const Card = ({ header = null, headerTextAlign, children, ...rest }) => {
    return (
        <Container {...rest}>
            {/* Card header */}
            {header ? (
                <CardHeader headerTextAlign={headerTextAlign}>{header}</CardHeader>
            ) : null}
            
            {/* Card body */}
            <Body>{children}</Body>
        </Container>
    )
}

export default Card
