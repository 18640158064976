import React from 'react'
import styled from 'styled-components'
import { FaSearch } from 'react-icons/fa';
import { colors } from '../../styles/globalStyles';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.white};
    height: 100vh;
    flex-direction: column;
`

const NotFound = () => {
    React.useEffect(() => {
        document.title = 'Страница не найдена'
    }, [])
    
    return (
        <Container>
            <FaSearch
                style={{
                    fontSize: '100px',
                    textAlign: 'center'
                }}
            />
            <h3 className="text-center mb-0 mt-4">Страница не найдена</h3>
        </Container>
    )
}

export default NotFound
