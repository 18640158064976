import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import LoginForm from '../../components/LoginForm'
import { useHistory } from 'react-router-dom'

import { AuthContext } from '../../context/AuthContext'
import { MeetingRoomService } from '../../services'

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`

const Login = () => {
    let history = useHistory();
    const { setLoading, setToken } = useContext(AuthContext)
    const [error, setError] = useState('');

    useEffect(() => {
        document.title = 'Авторизация'
    }, [])

    const handleLogin = async values => {
        try {
            setLoading(true);
            let { data } = await MeetingRoomService.login(values.username, values.password);
            let success = await setToken(data.token);
            localStorage.removeItem('mr_timeout');

            if(success) {
                history.push('/');
            } else {
                alert('Неизвестная ошибка!');
            }
        } catch (error) {
            console.log(error);
            setError(error.response.data.message);
        } finally {
            setLoading(false)
        }
    }

    return (
        <Container>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                {error ? (
                    <div className="alert alert-danger">{error}</div>
                ) : null}

                <LoginForm onLogin={handleLogin} />
            </div>
        </Container>
    )
}

export default Login
