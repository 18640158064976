import React, { useContext } from 'react'
import { Card, Input, Button } from '../ui'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { AuthContext } from '../../context/AuthContext';

const LoginSchema = Yup.object().shape({
    username: Yup.string()
        .min(4, 'Введите минимум 4 символа')
        .max(100, 'Слишком длинное имя пользователя')
        .required('Обязательное поле'),
    password: Yup.string()
        .min(5, 'Введите минимум 5 символов')
        .max(100, 'Слишком длинный пароль')
        .required('Обязательное поле'),
});

const LoginForm = ({ onLogin }) => {
    const { loading } = useContext(AuthContext);
    return (
        <Card
            header="Авторизация"
            headerTextAlign="center"
        >
            <Formik
                initialValues={{ username: '', password: '' }}
                validationSchema={LoginSchema}
                onSubmit={values => onLogin(values)}
            >
                {({ values, errors, touched, handleChange }) => (
                    <Form>
                        <div className="form-group">
                            <Input
                                name="username"
                                type="text"
                                placeholder="Введите имя пользователя"
                                label="Имя пользователя"
                                error={errors.username}
                                value={values.username}
                                onChange={handleChange('username')}
                            />
                        </div>

                        <div className="form-group">
                            <Input
                                name="password"
                                type="password"
                                placeholder="Введите пароль"
                                label="Пароль"
                                error={errors.password}
                                value={values.password}
                                onChange={handleChange('password')}
                            />
                        </div>

                        <div className="form-group">
                            <Button
                                type="submit"
                                loading={loading}
                            >
                                Войти
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Card>
    )
}

export default LoginForm
