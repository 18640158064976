import React from 'react'

const Spinner = ({ color, size = 50 }) => {
    return (
        <div
            className="spinner-border"
            role="status"
            style={{
                width: `${size}px`,
                height: `${size}px`,
                color: color
            }}
        >
            <span className="sr-only">Loading...</span>
        </div>
    )
}

export default Spinner
