import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { colors } from '../../styles/globalStyles'
import { Card, ProgressBar, Calendar } from '../../components/ui'

import AppHeader from '../../components/AppHeader'
import DetailsTable from '../../components/DetailsTable'
import NextMeeting from '../../components/NextMeeting'

import { MeetingRoomService } from '../../services'
import LoadingSpinner from '../../components/LoadingSpinner'
import EmptyMeetingRoom from '../../components/EmptyMeetingRoom'
import { AuthContext } from '../../context/AuthContext'

const Container = styled.div`
    background-color: ${colors.black};
    min-height: 100vh;
    font-family: 'Calibri' sans-serif;
`

const TimerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
`

const Home = () => {
    const { logout } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [room, setRoom] = useState({});
    const [meta, setMeta] = useState({});
    const [currentMeeting, setCurrentMeeting] = useState({});
    const [nextMeetings, setNextMeetings] = useState([]);
    const [busyDays, setBusyDays] = useState([]);
    const [nextMeetingsLabel, setNextMeetingsLabel] = useState('');

    const generateProgressBarHint = () => {
        const { hours, minutes, seconds } = currentMeeting?.meta?.time_remains;
        let timeString = '';

        if(Number(hours) !== 0) timeString += `${hours} час(ов) `;
        if(Number(minutes) !== 0) timeString += `${minutes} минут(ы) `;
        if(Number(seconds) !== 0) timeString += `${seconds} секунд(ы) `;

        return timeString;
    }

    const fetchData = async () => {
        try {
            const token = localStorage.getItem('mr_token');
            const timeoutFlag = localStorage.getItem('mr_timeout');
            let { data: { data } } = await MeetingRoomService.getByToken(token);

            document.title = data.room.name;

            setMeta(data.meta);
            setRoom(data.room);
            setCurrentMeeting(data.meetings.current);
            
            if(!timeoutFlag) {
                setNextMeetings(data.meetings.next);
                setNextMeetingsLabel('Следующее');
            }
            
            setBusyDays(data.busyDays);
        } catch (error) {
            console.log(error);

            if(error.response.status === 404) {
                await logout();
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
        let intervalId = setInterval(fetchData, 5000)

        return () => {
            clearTimeout(intervalId);
        }
    }, []);

    const handleOnCalendarDayClick = async (day) => {
        let timeoutId = null;
        try {
            clearTimeout(timeoutId);
            const token = localStorage.getItem('mr_token');
            const formattedDate = moment(day).format('YYYY-MM-DD');

            let { data: { data } } = await MeetingRoomService.getMeetingsByDate(token, formattedDate);
            localStorage.setItem('mr_timeout', 1);
            
            setNextMeetings(data.meetings);
            setNextMeetingsLabel(moment(day).format('DD.MM.YYYY'));

            timeoutId = setTimeout(() => {
                localStorage.removeItem('mr_timeout');
            }, 3000)
        } catch (error) {
            console.log(error)
        }
    }

    if(loading) {
        return <LoadingSpinner />
    }

    return (
        <>
        <Container>
            {/* App header */}
            <AppHeader
                room={room.name}
                image={room.company_image}
                date={meta.date}
                time={meta.time}
            />
            
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                {currentMeeting ? (
                    <Card
                        header={"Идет собрание"}
                        style={{
                            flex: 1
                        }}
                    >
                        <DetailsTable
                            user={currentMeeting?.user_name}
                            peopleQuantity={currentMeeting?.people_quantity}
                            topic={currentMeeting?.topic}
                            description={currentMeeting?.description}
                        />

                        <TimerContainer>
                            <ProgressBar
                                from={currentMeeting?.from}
                                to={currentMeeting?.to}
                                percent={currentMeeting?.meta?.percentage}
                                hint={generateProgressBarHint}
                            />
                        </TimerContainer>
                    </Card>
                ) : (
                    <EmptyMeetingRoom />
                )}

                <Calendar
                    busyDays={busyDays}
                    onDayClick={handleOnCalendarDayClick}
                />
            </div>

            {nextMeetings.map(meeting => (
                <NextMeeting
                    item={meeting}
                    key={meeting.id}
                    label={nextMeetingsLabel}
                />
            ))}
        </Container>
        </>
    )
}

export default Home
