import React from 'react';
import './App.css'
import {
    HashRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import PrivateRoute from './components/Routes/PrivateRoute'
import { Home, Login, NotFound } from './containers'
import AuthContextProvider from './context/AuthContext';

const App = () => {
    return (
        <AuthContextProvider>
            <Router>
                <Switch>
                    {/* Home page */}
                    <PrivateRoute exact path="/">
                        <Home />
                    </PrivateRoute>
                    {/* Login page */}
                    <Route path="/login">
                        <Login />
                    </Route>
                    {/* Not found page */}
                    <Route path="*">
                        <NotFound />
                    </Route>
                </Switch>
            </Router>
        </AuthContextProvider>
    )
}

export default App
