import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styles/globalStyles'

const Container = styled.div`
    border-bottom: 2px solid ${colors.red};
    text-transform: uppercase;
    padding: 15px 20px;
`

const CardHeader = ({ children, headerTextAlign }) => {
    return (
        <Container>
            <h3
                className="mb-0"
                style={{
                    textAlign: headerTextAlign
                }}
            >
                {children}
            </h3>
        </Container>
    )
}

export default CardHeader
