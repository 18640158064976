import React, { useContext } from 'react'
import styled from 'styled-components'
import { colors } from '../../styles/globalStyles'
import { useHistory } from 'react-router-dom'
import config from '../../config'

import { AuthContext } from '../../context/AuthContext'

const Container = styled.div`
    background-color: ${colors.red};
    padding: 10px 30px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: ${colors.white};
    align-items: center;
`

const DateTime = styled.div`
    text-align: right;
    flex: 1;
`

const CompanyImage = styled.img`
    width: 100px;
`

const LogoutBtn = styled.p`
    margin-bottom: 0;
    transition: 0;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`

const AppHeader = ({ room, image, date, time }) => {
    const { logout } = useContext(AuthContext);
    let history = useHistory();

    const handleLogout = async () => {
        let success = await logout();
        if(success) {
            history.push('/login');
        } else {
            alert('Неизвестная ошибка!');
        }
    }

    return (
        <Container>
            <div style={{ flex: 1 }}>
                <h5 className="mb-0">{room}</h5>
                <LogoutBtn className="mb-0" onClick={handleLogout}>Выйти</LogoutBtn>
            </div>

            {image ? (
                <CompanyImage
                    src={config.storageURL + '/' + image}
                    className="mb-0"
                />
            ) : null}
            
            <DateTime>
                <h1 className="mb-0">{time}</h1>
                <p className="mb-0">{date}</p>
            </DateTime>
        </Container>
    )
}

export default AppHeader
