import React from 'react'
import { Card } from '../ui'
import { MdAccessTime } from 'react-icons/md';

const EmptyMeetingRoom = () => {
    return (
        <Card
            style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <MdAccessTime
                    style={{
                        fontSize: '100px',
                        textAlign: 'center'
                    }}
                />
                <h3 className="text-center mb-0 mt-4">Нет текущих собраний</h3>
            </div>
        </Card>
    )
}

export default EmptyMeetingRoom
