import React from 'react'
import { colors } from '../../../styles/globalStyles'
import Spinner from '../Spinner'

const Button = ({ children, loading, ...rest  }) => {
    return (
        <button
            {...rest} 
            className="btn btn-success btn-block"
            disabled={loading}
        >
            {loading ? (
                <Spinner
                    color={colors.white}
                    size={20}
                />
            ) : children}
        </button>
    )
}

export default Button
